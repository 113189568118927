import { Container } from '@material-ui/core';
import { EmailOutlined, PhoneOutlined, RoomOutlined } from '@material-ui/icons';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ContactForm from '../components/ContactForm';
import IconInfo from '../ui/IconInfo';
import PageHeader from '../ui/PageHeader';

const Contato = () => {
  const { datoCmsSetting } = useStaticQuery(graphql`
    {
      datoCmsSetting {
        address
        phone
        email
      }
    }
  `);

  return (
    <>
      <Container maxWidth="sm">
        <PageHeader title="Entre em Contato" />
        <IconInfo icon={<RoomOutlined />} text={datoCmsSetting.address} />
        <IconInfo icon={<PhoneOutlined />} text={datoCmsSetting.phone} />
        <IconInfo icon={<EmailOutlined />} text={datoCmsSetting.email} mail />
        <ContactForm />
      </Container>
    </>
  );
};

export default Contato;
