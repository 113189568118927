import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';

const FormStyled = styled.form`
  div {
    width: 100%;
    margin-bottom: 0.5rem;
  }
`;

const ContactForm = () => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  const submitForm = (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus('SUCCESS');
      } else {
        setStatus('ERROR');
        setError('Algo deu errado, entre em contato por um dos outros canais');
      }
    };

    if (data.get('email') && data.get('nome') && data.get('mensagem')) {
      xhr.send(data);
      setError(null);
    } else {
      setError('Por favor, preencha todos os campos');
    }
  };

  return (
    <Box py={6}>
      <FormStyled
        onSubmit={submitForm}
        action="https://formspree.io/f/mnqodwwa"
        method="POST"
      >
        <div>
          <TextField type="text" name="nome" label="Nome" variant="outlined" />
        </div>
        <div>
          <TextField
            type="email"
            name="email"
            label="E-mail"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            type="textarea"
            name="mensagem"
            label="Mensagem"
            variant="outlined"
          />
        </div>
        {error && (
          <FormHelperText error style={{ marginBottom: '1rem' }}>
            Preencha todos os campos
          </FormHelperText>
        )}
        {status === 'SUCCESS' ? (
          <p>Thanks!</p>
        ) : (
          <Button
            type="submit"
            size="large"
            variant="contained"
            color="primary"
          >
            Enviar
          </Button>
        )}
        {status === 'ERROR' && <p>Ooops! There was an error.</p>}
      </FormStyled>
    </Box>
  );
};

export default ContactForm;
