/**
 * Criar avatar com styled-components
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Avatar, Container, Typography } from '@material-ui/core';

const PageHeaderStyled = styled(Container)`
  margin: 6rem auto 2rem;
  padding: 0;

  .title {
    margin-bottom: 1rem;
  }
`;

const AvatarStyled = styled(Avatar)`
  width: 150px;
  height: 150px;
  margin: 0.5rem 0 1.5rem 0;
  padding: 1rem;
  background: white;

  @media screen and (min-width: 600px) {
    width: 200px;
    height: 200px;
  }

  img {
    object-fit: contain;
  }
`;

const PageHeader = ({
  title = 'Acrescentar título',
  lead,
  category,
  logo,
  maxWidth,
}) => (
  <PageHeaderStyled component="header" maxWidth={maxWidth}>
    {logo ? <AvatarStyled variant="circle" src={logo} /> : null}
    {category ? <Typography>{category}</Typography> : null}
    <Typography className="title" variant="h3" component="h1">
      {title}
    </Typography>
    {lead ? <Typography variant="body2">{lead}</Typography> : null}
  </PageHeaderStyled>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  lead: PropTypes.string,
  category: PropTypes.string,
  logo: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default PageHeader;
